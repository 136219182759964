import React, { useContext, MouseEvent } from 'react';
import { Card, Badge } from 'react-bootstrap';
import { useFormContext } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import If from 'bundles/components/common/If';
import { setUrlParams, humanizeLabel } from 'bundles/utils';
import { FilterContext } from 'bundles/contexts/FilterContext/FilterContextProvider';
import { getPageData, setBadges } from './utils';
import { ComponentInterface } from './types';

const TableCardHeader = ({ TableHeaderTitle }: Pick<ComponentInterface, 'TableHeaderTitle'>) => {
  const { state, dispatch } = useContext(FilterContext);
  const { reset, setValue } = useFormContext();

  const handleResetFilter = (e: MouseEvent) => {
    e.preventDefault();
    setUrlParams();
    reset();
    getPageData(state, dispatch);
  };

  const handleClearBadge = (paramName: string ) => {
    setValue(`query[${paramName}]`, '');
    setUrlParams({ query: { [paramName]: '' } });
    getPageData(state, dispatch);
  };

  return (
    <Card.Header>
      <h2 className="w-100 d-flex justify-content-between h6 align-items-center">
        {TableHeaderTitle && <TableHeaderTitle />}
        <If condition={state.showBadges}>
          {setBadges(state)?.map((param, i) =>
            <Badge key={i} variant="gray" className="ml-2 py-2 pr-2 pl-3 align-middle" as="small">
              <strong>{param.humanizedName}</strong>{': '}{humanizeLabel(state.query_params[param.name])}
              <FontAwesomeIcon
                icon={faXmark}
                className="align-middle ml-3 bg-gray text-light rounded-circle"
                role="button"
                onClick={() => handleClearBadge(param.name)}
              />
            </Badge>
          )}
        </If>
      </h2>

      <If condition={!state.showTableCardHeaderTitleOnly && Boolean(window.location.search)}>
        <span onClick={handleResetFilter} className="text-gray remove-filter ml-3" role="button">
          <i className="fal fa-filter icon--outer"><i className="fas fa-times icon--inner bg-white" /></i>
        </span>
      </If>
    </Card.Header>
  );
};


export default TableCardHeader;
