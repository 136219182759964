import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useTranslation } from 'react-i18next';

import { locale } from './utils';
import { DatePickerInstance } from './types';
import { WithFormControlProps, InputRef } from 'bundles/hocs/withFormControl/types';

registerLocale('locale', locale);

const DatePickerComponent = React.forwardRef((props: WithFormControlProps, ref: InputRef) => {
  const { t } = useTranslation();
  const { placeholder = t('labels.date_format'), dateFormat = 'yyyy-MM-dd', monthsShown = 2, ...rest } = props.rest;

  return (
    <DatePicker
      placeholderText={placeholder}
      locale="locale"
      dateFormat={dateFormat}
      autoComplete="off"
      monthsShown={monthsShown}
      onChange={(v: string) => props.onChange(v)}
      {...rest}
      ref={(instance: DatePickerInstance) => (instance && ref(instance.input))}
    />
  );
});

DatePickerComponent.displayName = 'Datepicker';

export default DatePickerComponent;
