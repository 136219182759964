import React, { FC } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { WithFormControlProps, InputRef } from 'bundles/hocs/withFormControl/types';

import './styles.scss';

const PhoneNumber: FC<any> = React.forwardRef((props: WithFormControlProps, ref: InputRef) => {
  return (
    <PhoneInput
      country={'us'}
      inputProps={{
        name: props.rest.name,
        className: props.rest.className,
        id: (props.rest.id || props.rest.name),
        required: props.rest.required,
        pattern: '.{7,20}',
        ref: ref || undefined
      }}
      onChange={props.onChange}
      autoFormat={false}
      {...(props.value ? { value: props.value } : {})}
    />
  );
});

PhoneNumber.displayName = 'PhoneNumber';

export default PhoneNumber;

